import {
  createSlice,
  createEntityAdapter,
  createAsyncThunk,
} from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import axiosInstance from "../../utils/axiosInstance";

const messageAlert = new URL("../../assets/sounds/soft.mp3", import.meta.url);

export const GetNotifications = createAsyncThunk(
  "GetNotifications",
  async (page, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(
        `notifications/all/?page=${page}`
      );
      return response.data;
    } catch (error) {
      toast.error(error.message);
      return rejectWithValue(error.message);
    }
  }
);

export const ReadNotifications = createAsyncThunk(
  "ReadNotifications",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`notifications/read/`, {
        data,
      });
      return response.data;
    } catch (error) {
      toast.error(error.message);
      return rejectWithValue(error.message);
    }
  }
);

const NotificationAdpater = createEntityAdapter({
  selectId: (state) => state.id,
});

const initialState = NotificationAdpater.getInitialState({
  loading: false,
  next: null,
});

const NotificationSlice = createSlice({
  name: "notifications",
  initialState: initialState,
  reducers: {
    newNotification(state, action) {
      const user = JSON.parse(localStorage.getItem("user"));
      const messageAlarm = new Audio(messageAlert);
      if (Array.isArray(action.payload)) {
        const list = action.payload.filter((notification) =>
          notification.notification_level.includes(user?.user_type)
        );
        if (list.length) {
          NotificationAdpater.upsertMany(state, list);
          messageAlarm.play();
        }
      } else {
        if (action.payload?.notification_level.includes(user?.user_type)) {
          NotificationAdpater.addOne(state, action.payload);
          messageAlarm.play();
        }
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(GetNotifications.fulfilled, (state, action) => {
        NotificationAdpater.addMany(state, action.payload?.results);
        state.next = action.payload?.next;
      })
      .addCase(ReadNotifications.fulfilled, (state, action) => {
        NotificationAdpater.upsertMany(state, action.payload);
      });
  },
});
export const {
  selectAll: selectAllNotifications,
  selectById: selectNotificationById,
  selectIds: selectNotificationIds,
} = NotificationAdpater.getSelectors((state) => state.notifications);

export const { newNotification } = NotificationSlice.actions;

export default NotificationSlice.reducer;
