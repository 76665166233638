import React, { memo } from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

function RequireQuality({ children }) {
  const { isAuthenticated, user, AuthSuccessLoading } = useSelector(
    (state) => state.auth
  );
  if (AuthSuccessLoading) return null;
  if (!user && !AuthSuccessLoading) return null;
  if (!isAuthenticated) return <Navigate to="/login" />;
  if ((user && user?.user_type === "QUALITY") || user?.username === "dev")
    return children;

  return <Navigate to="/" />;
}

export default memo(RequireQuality);

// || user?.user_type === "DEVELOPER")
