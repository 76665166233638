import React from "react";
import Breadcrumbs from "@mui/joy/Breadcrumbs";
import Typography from "@mui/joy/Typography";
import { IoIosArrowBack, IoMdArrowRoundBack } from "react-icons/io";
import { useNavigate, Link } from "react-router-dom";
import { IconButton, Sheet } from "@mui/joy";

function PageHeader({ current_page, prev_page, url }) {
  const navigate = useNavigate();
  return (
    <Sheet
      sx={{
        bgcolor: "neutral.softBg",
        mb: 2,
        display: "flex",
        justifyContent: "space-between",
        padding: 1,
      }}
    >
      <IconButton variant="soft" color="neutral" onClick={() => navigate(-1)}>
        <IoMdArrowRoundBack />
      </IconButton>
      <Breadcrumbs separator={<IoIosArrowBack />} aria-label="breadcrumbs">
        <Typography>{current_page}</Typography>
        <Link to={url}> {prev_page}</Link>
      </Breadcrumbs>
    </Sheet>
  );
}

export default PageHeader;
