import React from "react";
import MainComponent from "../../components/main/Main";
import { Outlet } from "react-router-dom";

function HomeContainer() {
  return (
    <MainComponent>
      <Outlet />
    </MainComponent>
  );
}

export default HomeContainer;
