import React from "react";

import { Link } from "react-router-dom";

const CustomToast = ({ url, message }) => {
  return (
    <div>
      <Link
        style={{
          position: "absolute",
          width: "100%",
          height: "100%",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
        }}
        to={url}
      ></Link>
      {message}
    </div>
  );
};

export default React.memo(CustomToast);
