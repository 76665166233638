import {
  createEntityAdapter,
  createSlice,
  createAsyncThunk,
} from "@reduxjs/toolkit";
import axiosInstance from "../../utils/axiosInstance";
import { toastOnError } from "../../utils/utils";
import { toast } from "react-toastify";
import CustomToast from "../../components/custom-toast/CustomToast";

const messageAlert = new URL("../../assets/sounds/soft.mp3", import.meta.url);

export const GetITLogs = createAsyncThunk(
  "getITLogs",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get("script/it-users/");

      return response.data;
    } catch (e) {
      toastOnError(e);
      return rejectWithValue(e?.response?.data);
    }
  }
);

const ITLogsAdapter = createEntityAdapter({
  selectId: (log) => log.username,
});

const initialState = ITLogsAdapter.getInitialState({
  isLoading: false,
});

const ITReducer = createSlice({
  name: "ITReducer",
  initialState: initialState,
  reducers: {
    appendNewLog(state, action) {
      const data = action.payload;
      const log_for = state.entities[data.message.data.username];
      if (log_for) {
        log_for.daily_logs.push(data.message.data);
        if (!log_for.profile.online) log_for.profile.online = 1;
      }
    },
    appendPreShiftCheck(state, action) {
      const data = action.payload;
      const preCheck_for = state.entities[data.message.data.username];
      if (preCheck_for) {
        preCheck_for.preCheck = data.message.data;
      }
    },
    userDisconnect(state, action) {
      const data = action.payload;
      const user = state.entities[data.message.data];
      if (user) {
        user.profile.online = 0;
      }
    },
    appendUserLogs(state, action) {
      const data = action.payload;
      const log_for = state.entities[data.message.data?.[0]?.username];
      if (log_for) {
        log_for.daily_logs = data.message.data;
      }
    },

    appendUserStatus(state, action) {
      const data = action.payload;
      data.message?.data.map((item) => {
        let user = state.entities[item.username];
        if (user && user?.status_for) {
          const updated_status = user?.status_for?.find(
            (status) => status.id === item.id
          );
          if (updated_status) updated_status.duration = item.duration;
          else {
            user.status_for = [...user.status_for, item];
          }
        }
        return user;
      });
    },
    // changeHeadset(state, action) {
    //   const data = action.payload;
    //   const user = state.entities[data?.message?.data?.user];
    //   if (user) {
    //     user.profile.headset = data?.message?.data?.headset;
    //     toast.warning(
    //       <CustomToast
    //         url={`it/${user?.username}`}
    //         message={`${user?.username} Change Headset to ${data?.message?.data?.headset}`}
    //       />
    //     );
    //     const messageAlarm = new Audio(messageAlert);
    //     messageAlarm.play();
    //   }
    // },
  },
  extraReducers: (builder) => {
    builder.addCase(GetITLogs.fulfilled, (state, action) => {
      ITLogsAdapter.addMany(state, action.payload);
    });
  },
});

export default ITReducer.reducer;
export const { selectAll: SelectAllITLogs, selectById: SelectLogByID } =
  ITLogsAdapter.getSelectors((state) => state.it);

export const {
  appendNewLog,
  appendPreShiftCheck,
  appendUserLogs,
  appendUserStatus,
  userDisconnect,

  // changeHeadset,
} = ITReducer.actions;
