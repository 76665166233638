import styled from "@emotion/styled";
import { Container, Sheet } from "@mui/joy";
import React from "react";

const Main = styled(Sheet)(({ theme }) => ({
  marginTop: "90px",
  width: "100%",
  height: "100%",
  padding: "10px 0",
}));

function MainComponent({ children }) {
  return (
    <Main color="neutral" variant="soft" component="main">
      <Container maxWidth="xl">{children}</Container>
    </Main>
  );
}

export default MainComponent;
