import useWebSocket from "react-use-websocket";
import { socketUrl } from "../utils/utils";
import { useSelector } from "react-redux";
import { SelectAdmins } from "../redux/login-slice/login.slice";

function useMyWebSocket(url, options) {
  const isAdmin = useSelector(SelectAdmins);
  const token = useSelector((state) => state.auth.token);

  const {
    onMessage,
    onClose,
    onError,
    onReconnectStop,
    reconnectAttempts,
    shouldReconnect,
    retryOnError,
    onOpen,
    reconnectInterval,
    share,
  } = options;

  return useWebSocket(
    `${socketUrl}${url}`,
    {
      queryParams: { token },
      onMessage,
      onClose,
      onError,
      onReconnectStop,
      reconnectAttempts,
      shouldReconnect,
      retryOnError,
      onOpen,
      reconnectInterval,
      share,
    },
    isAdmin
  );
}

export default useMyWebSocket;
