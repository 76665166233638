import axios from "axios";
import { toast } from "react-toastify";

export const setAxiosAuthToken = (token) => {
  if (typeof token !== "undefined" && token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
  } else {
    delete axios.defaults.headers.common["Authorization"];
  }
};

export const toastOnError = (error) => {
  if (error.response.data) {
    if (error.response.data.extra?.fields?.non_field_errors) {
      toast.error(JSON.stringify("email or password invalid"));
    } else if (error.response.data?.extra?.fields?.password) {
      toast.error(JSON.stringify("password invalid"));
    } else if (error.response?.extra?.fields?.data?.email) {
      toast.error(JSON.stringify("invalid email"));
    } else if (error.response.data?.extra?.fields?.username) {
      toast.error(JSON.stringify("invalid username"));
    } else {
      toast.error(JSON.stringify(error.response.data?.message));
    }
    // known error
    // toast.error(JSON.stringify(error.response.data));
  } else if (error.message) {
    toast.error(JSON.stringify(error.message));
  } else {
    toast.error(JSON.stringify(error));
  }
};

export const isEmpty = (value) =>
  value === undefined ||
  value === null ||
  (typeof value === "object" && Object.keys(value).length === 0) ||
  (typeof value === "string" && value.trim().length === 0);

let baseURL;
let socketUrl;
const protocol = window.location.protocol;
const host = window.location.host;

if (window.location.origin === "http://localhost:3000") {
  baseURL = "http://127.0.0.1:8000/";
  socketUrl = `ws://127.0.0.1:8000/`;
} else {
  baseURL = `${window.location.origin}/`;
  socketUrl = `${protocol === "http:" ? "ws:" : "wss:"}${host}/`;
}

export { baseURL, socketUrl };

export function byteToMegaBit(num) {
  return (num * 8 * 10 ** -6).toFixed(2);
}

export function format_duration(duration) {
  if (duration) {
    let formated = duration.split(":");
    if (formated.length === 3) {
      formated[2] = parseFloat(formated[2]).toFixed(1);
      return formated.join(":");
    }
  }
  return duration;
}

export function debounce(func, timeout = 300) {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, args);
    }, timeout);
  };
}
