import React from "react";

import { Link } from "react-router-dom";
import Card from "@mui/joy/Card";
import CardContent from "@mui/joy/CardContent";
import Typography from "@mui/joy/Typography";

import { FaRegClock } from "react-icons/fa";

import { formatDistanceToNow, parseISO } from "date-fns";

function NotificationCard({ item }) {
  const date = parseISO(item?.timestamp);
  const timeAgo = formatDistanceToNow(date ? date : new Date());
  return (
    <Card
      variant={item?.is_read ? "plain" : "soft"}
      color={item?.is_read ? "neutral" : "danger"}
      sx={{ width: "250px", margin: "5px 10px" }}
      component="div"
    >
      <CardContent>
        <Link to={item.url}>
          <Typography level="title-sm">{item.message}</Typography>
          <Typography
            level="body-sm"
            sx={{ display: "flex", alignItems: "center", gap: 0.5 }}
          >
            <FaRegClock />
            {timeAgo}
          </Typography>
        </Link>
      </CardContent>
    </Card>
  );
}

export default NotificationCard;
