import api from "../api";

const KpiSlice = api.injectEndpoints({
  endpoints: (builder) => ({
    GetDepartmentQuestion: builder.query({
      query: () => `kpi/questions/`,
    }),
    GetMissingKpi: builder.query({
      query: () => `kpi/missing/`,
      providesTags: [{ type: "MissingKpi", id: "LIST" }],
    }),
    AnswerKpi: builder.mutation({
      query: (data) => ({
        url: "kpi/answer/",
        method: "POST",
        body: data,
      }),
      invalidatesTags: [
        { type: "MissingKpi", id: "LIST" },
        { type: "KPILIST", id: "LIST" },
      ],
    }),
    GetKpiTable: builder.query({
      query: (dates) => `kpi/range/?year=${dates?.year}&month=${dates?.month}`,
      providesTags: [{ type: "KPILIST", id: "LIST" }],
    }),
    GetKpiCharts: builder.query({
      query: (data) =>
        `kpi/charts/?year=${data?.year}&username=${data?.username}`,
    }),
    GetUserAnswers: builder.query({
      query: (data) =>
        `kpi/kpi_answers/?username=${data?.username}&year=${data?.year}&month=${data?.month}`,
      providesTags: [{ type: "UserAnswer", id: "LIST" }],
    }),
    UpdateKpi: builder.mutation({
      query: (data) => ({
        url: "kpi/update/",
        method: "POST",
        body: data,
      }),
      invalidatesTags: [
        { type: "KPILIST", id: "LIST" },
        { type: "UserAnswer", id: "LIST" },
      ],
    }),
  }),
});

export const {
  useGetDepartmentQuestionQuery,
  useGetMissingKpiQuery,
  useAnswerKpiMutation,
  useGetKpiTableQuery,
  useGetKpiChartsQuery,
  useGetUserAnswersQuery,
  useUpdateKpiMutation,
} = KpiSlice;
