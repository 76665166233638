import React, { memo } from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

function RequireAuth({ children }) {
  const { isAuthenticated, user, AuthSuccessLoading } = useSelector(
    (state) => state.auth
  );
  if (AuthSuccessLoading) return null;
  if (!isAuthenticated) return <Navigate to="/login" />;
  if (user?.user_type === "INTERPRETER" || user?.user_type === "GUEST")
    return <Navigate to="/login" />;

  return children;
}

export default memo(RequireAuth);
