import {
  Badge,
  Button,
  Dropdown,
  Menu,
  MenuButton,
  MenuItem,
  Typography,
} from "@mui/joy";
import React from "react";
import { IoNotificationsSharp } from "react-icons/io5";
import NotificationCard from "../notification-card/NotificationCard";
import { useDispatch, useSelector } from "react-redux";
import {
  GetNotifications,
  selectAllNotifications,
  ReadNotifications,
} from "../../redux/notification/notification-slice";

function Notification() {
  const [page, setPage] = React.useState(1);
  const [open, setOpen] = React.useState(false);

  const [unread, setUnread] = React.useState([]);
  const data = useSelector(selectAllNotifications);
  const has_next = useSelector((state) => state.notifications.next);
  const dispatch = useDispatch();
  const calcCount = React.useMemo(() => {
    let countNum = [];
    data &&
      data.filter((item) =>
        item?.is_read === false ? countNum.push(item.id) : null
      );
    setUnread(countNum);
  }, [data]);

  React.useEffect(() => {
    dispatch(GetNotifications(page));
  }, [page, dispatch]);

  const changePage = (e) => {
    e.stopPropagation();
    if (has_next) {
      setPage(page + 1);
    }
  };
  const toggle = () => {
    setOpen(!open);
    if (open && unread.length) {
      dispatch(ReadNotifications(unread));
    }
  };

  return (
    <Dropdown onOpenChange={toggle} open={open} defaultOpen>
      <Badge
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        size="sm"
        variant="solid"
        badgeContent={unread.length}
        color="danger"
      >
        <MenuButton size="sm" color="neutral" variant="soft">
          <IoNotificationsSharp />
        </MenuButton>
      </Badge>
      <Menu
        sx={{ zIndex: "200000", maxHeight: "350px" }}
        variant="soft"
        color="neutral"
      >
        <MenuItem disabled variant="plain">
          <Typography level="title-lg">Notifications</Typography>
        </MenuItem>

        {data &&
          data
            .sort((a, b) =>
              new Date(a.timestamp) > new Date(b.timestamp) ? -1 : 1
            )
            .map((item) => <NotificationCard item={item} key={item.id} />)}

        <MenuItem
          component="li"
          color="primary"
          variant="palin"
          sx={{
            textAlign: "center",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Button
            disabled={has_next ? false : true}
            color="primary"
            variant="solid"
            onClick={changePage}
          >
            Load More
          </Button>
        </MenuItem>
      </Menu>
    </Dropdown>
  );
}

export default React.memo(Notification);
