import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Grid,
  Sheet,
  Slider,
  Textarea,
  Typography,
} from "@mui/joy";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import PageHeader from "../../components/page-header/PageHeader";
import { useUpdateKpiMutation } from "../../redux/kpi/kpi-slice";
import { toast } from "react-toastify";

function EditKpi() {
  const { state } = useLocation();
  const [updateKpi] = useUpdateKpiMutation();
  const navigate = useNavigate();
  const onSubmit = (e) => {
    e.preventDefault();
    const toServer = [];
    const formElements = e.currentTarget.elements;

    for (const question of state?.item?.department_answers) {
      const question_data = formElements[question?.question?.question];
      const single_value = { id: question.id };
      const point = question?.question?.weight / 100;
      const score = point * question_data.value;
      single_value.score = score;
      toServer.push(single_value);
    }

    updateKpi({
      kpiDep: state?.item?.id,
      answers: toServer,
      comment: formElements.comment.value,
    })
      .unwrap()
      .then(() => {
        toast.success("update");
        navigate(`/profile/${state?.user}`);
      })
      .catch((e) => {
        toast.error(e?.data?.message);
      });
  };
  return (
    <Box>
      <PageHeader current_page="Edit Kpi" prev_page="Home" url="/" />
      <Sheet
        variant="plain"
        sx={{
          borderRadius: 10,
          boxShadow: "lg",
          padding: 3,
          minHeight: "75vh",
        }}
      >
        <Typography
          level="body-lg"
          textTransform="uppercase"
          textColor="text.tertiary"
          sx={{ letterSpacing: "0.15rem", mb: 2 }}
        >
          Edit KPI For {state?.user}
        </Typography>

        <Grid container spacing={2} component="form" onSubmit={onSubmit}>
          {state?.item?.department_answers?.map((item) => (
            <Grid xs={12} key={item.id}>
              <FormControl>
                <FormLabel>{item?.question?.question}</FormLabel>
                <Slider
                  sx={{
                    "--Slider-markSize": "5px",
                    my: 3,
                  }}
                  slotProps={{
                    input: { "data-weight": item?.question?.weight },
                  }}
                  defaultValue={item?.score?.toFixed(2) * 10}
                  valueLabelDisplay="on"
                  // marks={marks}
                  name={item?.question?.question}
                  min={1.0}
                  max={100.0}
                  step={0.5}
                />
              </FormControl>
            </Grid>
          ))}
          <Grid xs={12}>
            <Textarea
              placeholder="Comment"
              type="textarea"
              minRows={3}
              name="comment"
              defaultValue={state?.item?.comment}
            />
          </Grid>
          <Grid xs={12}>
            <Button type="submit">Submit</Button>
          </Grid>
        </Grid>
      </Sheet>
    </Box>
  );
}

export default EditKpi;
